import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Tag, TextField, secondaryColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledDropdownMenuContent = styled(DropdownMenu.Content)<{ $searchHeight: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.lighter};
  border-radius: 5px;
  width: var(--radix-dropdown-menu-trigger-width);
  height: ${({ $searchHeight }) =>
    $searchHeight ? 'auto' : 'calc(var(--radix-dropdown-menu-content-available-height) - 9rem)'};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  box-shadow: ${({ theme }) => theme.boxShadow.light};
  overflow-y: scroll;
  padding-bottom: 1.6rem;
  color: ${({ theme }) => theme.colors.text.main};
  position: relative;
  z-index: 2147483003;
`;

export const StyledDropdownMenuItemGroup = styled(DropdownMenu.Group)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const StyledDropdownMenuItemGroupAllUsers = styled(StyledDropdownMenuItemGroup)`
  margin: 0.7rem 0;
`;

export const StyledDropdownLabel = styled(DropdownMenu.Label)`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  padding: 0.7rem 2.4rem;
`;

export const StyledDropdownMenuSeparator = styled(DropdownMenu.Separator)`
  border: 0.1rem solid ${({ theme }) => theme.colors.divider};
`;

export const StyledDropdownTrigger = styled(DropdownMenu.Trigger)`
  width: 100%;
`;

export const StyledDropdownCheckboxItem = styled(DropdownMenu.CheckboxItem)<{ $isDarkMode: boolean }>`
  padding: 0.7rem 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.4rem;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius.small};

  :hover,
  :focus {
    border: 0;
    background-color: ${({ theme, $isDarkMode }) =>
      $isDarkMode ? secondaryColors.secondary.lighter : theme.colors.grey[200]};
  }

  & > button[role='checkbox'] {
    flex-shrink: 0;
  }

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTagWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding: 1.6rem 1.6rem;
  flex-wrap: wrap;
  height: max-content;
`;

export const StyledTag = styled(Tag)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTriggerButton = styled(Button)<{ $isEmpty?: boolean; $isOpen?: boolean; $isDarkMode: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.2rem;
  font-size: ${({ theme }) => theme.fonts.sizes.small};
  color: ${({ theme, $isEmpty }) => ($isEmpty ? theme.colors.text.lightest : theme.colors.text.main)};

  :hover:not(:disabled) {
    background-color: ${({ $isDarkMode }) => ($isDarkMode ? secondaryColors.secondary.main : secondaryColors.white)};
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    transition: transform 0.3s ease-in-out;
    transform: ${({ $isOpen }) => `rotate(${$isOpen ? '-180deg' : '0deg'})`};
    height: 1rem;
    width: 1rem;
    color: ${({ theme }) => theme.colors.text.lightest};
  }
`;

export const StyledTextFieldWrapper = styled.div`
  > div > div {
    border: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background.lighter};
  }

  padding: 1.2rem 1.2rem;
`;

export const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.background.lighter};
`;

export const StyledSearchIcon = styled(FontAwesomeIcon)<{ $isDarkMode: boolean }>`
  && {
    color: ${({ theme, $isDarkMode }) => ($isDarkMode ? theme.colors.grey[400] : theme.colors.grey[700])};
  }
`;

export const StyledText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  color: ${({ theme }) => theme.colors.text.main};
  white-space: nowrap;
`;
